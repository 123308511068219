@import 'import';
.DateRefinement {
  $mobileMaxWith: 430px;

  @include media-breakpoint-down(md) {
    margin-left: 77px;
  }

  @include media-breakpoint-down(sm) {
    overflow: hidden;
    height: 100%;
    margin-left: unset;

    .DateRefinementContent {
      position: fixed;
      z-index: $z-mobile-refinement-filter;
      top: 0;
      left: 0;

      width: 100vw;
      height: 100vh;

      background-color: $white;
    }

    .filter-popup__header {
      position: absolute;
      z-index: 510;

      width: 100%;

      background-color: $gray-085;
      backdrop-filter: blur(2px);
      border-bottom: solid 1px $lightwhite;
    }

    .DatePicker {
      overflow-y: auto;

      max-width: $mobileMaxWith;
      height: 100%;
      margin: 0 auto;
      padding: 60px 25px 200px;

      // LandScape View for Mobiles
      @media screen and (max-width: 850px) and (max-height: 500px) {
        margin: auto;
      }
    }

    .RefinementButtons {
      z-index: $z-mobile-refinement-filter-buttons;
    }
  }

  .arrowsNYear {
    margin-top: 0;
  }
}
