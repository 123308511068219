@import 'import';
$animation-speed: 300ms;
$border-color: $grey-001;

.DatePicker {
  margin-bottom: 10px;

  &__yearGroup {
    max-width: none;
    margin-top: 20px;
    padding-top: 0 !important;

    .datepickerHeader {
      display: inline-block;
      font-size: 15px;
      color: $lightgrey2;
    }

    .resetMonthsSelected {
      cursor: pointer;
      float: right;
      font-size: 15px;
      text-decoration: underline;
    }

    .arrowsNYear {
      margin-top: 0 !important;
      text-align: center;

      h2 {
        @include font($size: rem(14), $weight: 'bold', $name: 'body');

        display: inline-block;
        margin: 0 0 5px;
        padding-bottom: 10px;
        font-size: 17px;
      }

      .YearGroup__arrow {
        padding: 0;
        color: #e10a0a;
        background-color: transparent;
        border: 0;

        &._left {
          transform: rotate(180deg);
          float: left;
        }

        &._right {
          float: right;
        }

        &:disabled {
          color: grey;
        }
      }
    }

    @include media-breakpoint-down(md) {
      margin: auto;
    }
  }

  &__monthContainer {
    display: flex;
    flex-wrap: wrap;
  }

  &__month {
    position: relative;

    display: block;
    flex: 0 0 25%;

    max-width: 25%;
    height: 46px;
    margin-bottom: 5px;

    color: $black;

    background: none;
    border: 0;

    &::before {
      content: '';

      position: absolute;
      top: 0;

      display: block;

      width: 50%;
      height: 100%;

      opacity: 0;
      background: lighten($tropicalblue, 15%);
    }

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(0);

      display: block;

      width: 48px;
      height: 48px;
      margin: -24px 0 0 -24px;

      border: 2px solid $tropicalblue;
      border-radius: 100%;

      transition: transform $animation-speed;
    }

    span {
      position: relative;
      z-index: 1;
    }

    &:focus-visible {
      z-index: 2;
    }

    // Hover in between start/end dates while we are mid-selection
    &.-activeHover {
      background: $tropicalblue;

      &.-newFilter {
        font-weight: bold;
      }
    }

    // Once start/end selected, the months in between
    &.-inRange {
      background: lighten($tropicalblue, 15%);

      &.-newFilter {
        font-weight: bold;
      }

      &.-hoverDate {
        background: $tropicalblue;
      }
    }

    // The selected start month
    &.-rangeStart {
      font-weight: 500;

      &::before {
        left: 50%;
        opacity: 1;
      }

      &::after {
        transform: scale(1);
        opacity: 1;
        background: $white;
      }

      &.-newFilter {
        font-weight: bold;
      }

      &.-hoverDate::before {
        background: $tropicalblue;
      }
    }

    &.-selectedMonth {
      &::before {
        opacity: 0;
      }
    }

    // The selected end range month
    &.-rangeEnd {
      font-weight: 500;

      &::before {
        left: 0;
        opacity: 1;
      }

      &::after {
        transform: scale(1);
        opacity: 1;
        background: $white;
      }

      &.-newFilter {
        font-weight: bold;
      }

      @include media-breakpoint-up(xl) {
        &.-hoverDate::before {
          background: $tropicalblue;
        }
      }
    }

    // Show no background if range start is the same month as range end
    &.-rangeStart.-rangeEnd {
      &::before {
        opacity: 0;
      }
    }

    // The left side of the range while hovering
    &.-startHover {
      background: $tropicalblue;
    }

    // The right side of the range while hovering
    &.-endHover {
      &::before {
        left: 0;
        width: 100%;
        opacity: 1;
        border-radius: 0 24px 24px 0;
      }

      &.-newFilter {
        font-weight: bold;
      }
    }

    // Disbaled month
    &.-disabled {
      opacity: 0.3;
    }

    &.-hideable {
      display: none;
    }

    @include font($size: rem(14), $color: $black, $name: 'body');
  }

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    max-width: none;

    &__yearGroup {
      width: 300px;
      margin-top: 0;

      & + & {
        margin-left: 20px;
      }

      h2 {
        border-bottom: 0;
      }
    }

    &__month {
      height: 40px;

      &::after {
        width: 42px;
        height: 42px;
        margin: -21px 0 0 -21px;
      }

      &.-hideable {
        display: block;
      }

      &.-endHover {
        &::before {
          border-radius: 0 20px 20px 0;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__yearGroup {
      width: 300px;
    }
  }

  &.-newFilter {
    @include media-breakpoint-down(sm) {
      @include no-scrollbar;

      overflow: auto;
      flex-grow: 1;
      padding-top: 15px;
      padding-bottom: 10px;
    }

    .FilterPopover__applyButton.-newFilter {
      @include media-breakpoint-down(sm) {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      @include media-breakpoint-up(sm) {
        right: 0;
        bottom: 0;
        width: auto;
      }
    }

    .DatePicker {
      &__yearGroup {
        .DatePicker__month {
          height: 47px;
        }

        @include media-breakpoint-up(md) {
          width: 285px;

          &:last-child {
            margin-left: 60px;
          }

          .DatePicker__month {
            &::after {
              width: 47px;
              height: 47px;
              margin: -24px 0 0 -23px;
            }
          }
        }

        @include media-breakpoint-up(lg) {
          width: 338px;

          &:last-child {
            margin-left: 74px;
          }
        }

        .arrowsNYear {
          h2 {
            margin-bottom: 20px;
            padding: 0;

            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            color: $grey-text;
          }

          .YearGroup__arrow {
            &._left {
              margin-right: -24px;
            }

            &._right {
              margin-left: -24px;
            }
          }
        }
      }

      &__monthContainer {
        .DatePicker__month {
          margin-bottom: 8px;
        }
      }

      &__yearGroup:not(:last-child) {
        @include media-breakpoint-down(sm) {
          margin-bottom: 22px;
        }
      }
    }
  }
}

.-newFilter.DateRefinement {
  @include media-breakpoint-down(xs) {
    justify-content: space-between;
  }
}
