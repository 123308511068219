@import 'import';
.filter-popup-transition {
  > div {
    opacity: 1;
    transition-property: opacity;
  }

  &[data-status='open'] > div,
  &[data-status='close'] > div {
    transition-duration: 450ms;
  }

  &[data-status='initial'] > div,
  &[data-status='close'] > div {
    opacity: 0;
  }
}

.filter-popup {
  position: absolute;
  z-index: 910;
  top: 0;
  left: 0;

  width: 810px;

  background-color: $white;
  filter: drop-shadow(0 1px 3px rgb(190 42 42 / 20%));
  box-shadow:
    0 2px 8px 0 $black-0004,
    0 4px 20px 0 rgb(0 0 0 / 9%);

  @include media-breakpoint-down(md) {
    width: 686px;
  }

  @include media-breakpoint-down(sm) {
    position: unset;
    z-index: unset;
    top: unset;
    left: unset;

    width: unset;
    height: unset;
  }

  &__header {
    display: flex;
    padding: 30px;

    @include font($size: rem(17), $color: $grey-text, $line-height: rem(25), $weight: 700);

    letter-spacing: 0;

    &__button {
      position: absolute;

      display: none;
      align-items: center;
      justify-content: center;

      width: 60px;
      height: 60px;
      margin: 0;
      padding: 0;

      line-height: 60px;

      background-color: transparent;
      border: 0;

      .Icon,
      .Icon span,
      svg {
        display: block;
        width: 24px;
        height: 24px;
        color: $black;
      }
    }

    @include media-breakpoint-down(sm) {
      position: unset;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      height: 60px;

      &__button {
        display: flex;

        &-back {
          top: 0;
          left: 0;
        }

        &-close {
          top: 0;
          right: 0;

          .Icon.Close,
          .Icon.Close span,
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;

      &__title-text {
        margin-left: 9px;
        color: $black;
      }

      > span.Icon,
      span.Icon span,
      svg {
        display: block;
        width: 24px;
        height: 24px;
        color: $black;
      }
    }
  }
}

body.-refinementOpen {
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}
